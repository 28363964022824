import { template as template_0f1d900f5a4e4cb7b4e80aff1af37316 } from "@ember/template-compiler";
const FKControlMenuContainer = template_0f1d900f5a4e4cb7b4e80aff1af37316(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
