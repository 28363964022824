import { template as template_4534321511c54d4b8920416bb5007db8 } from "@ember/template-compiler";
const FKLabel = template_4534321511c54d4b8920416bb5007db8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
